/* 
.btn,
input[type="submit"]{
	cursor: pointer;
    border-radius: 0px;
    text-decoration: none;
    padding: 12px 18px;
    font-size: 12px;
    line-height: 19px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif; font-weight:400;
    letter-spacing: 3px;
    -webkit-transition: all .4s ease-in-out;
       -moz-transition: all .4s ease-in-out;
        -ms-transition: all .4s ease-in-out;
         -o-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
} */

#container_padding_logo {
  padding-right: 10px;
  padding-left: 10px;
}

#container_padding {
  padding-right: 20px;
  padding-left: 20px;
}
@media screen and (max-width: 991px) {
  #center_text {
    text-align: center;
  }
}

@media screen and (min-width: 650px) {
  #container_padding_logo {
    padding-right: 80px;
    padding-left: 80px;
  }
  #container_padding {
    padding-right: 100px;
    padding-left: 100px;
  }
}

@media screen and (min-width: 1660px) {
  #container_padding_logo {
    padding-right: 170px;
    padding-left: 170px;
  }
  #container_padding {
    /* padding-right: 160px;
    padding-left: 160px; */

    padding-right: 200px;
    padding-left: 200px;
  }
}

.btn,
input[type="submit"] {
  cursor: pointer;
  border-radius: 0px;
  text-decoration: none;
  padding: 12px 18px;
  font-size: 12px;
  line-height: 19px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  letter-spacing: 3px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.btn-mid {
  border-radius: 0px;
  text-decoration: none;
  padding: 14px 21px;
  font-size: 13px;
  line-height: 25px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  letter-spacing: 3px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.btn-white {
  border: solid 2px #fff;
  background: transparent;
  color: #fff !important;
}

.button-primary-middle {
  width: 100%;
  clear: both;
  color: white;
  margin: 0;
  position: relative;

  /* display: block;
  width: 100%;
  clear: both;
  color: white;
  position: relative;
  margin: 0; */
}

.button-primary-middle input[type="submit"] {
  width: 15.375rem;
  min-width: 0.625rem;
  height: 2.688rem;
  min-height: 0.625rem;
  margin: auto 0;
  /* border-radius: 1.3125rem; */
  border-radius: 5px;

  box-shadow: none;
  color: #ffffff;
  font-family: inherit;
  font-size: 0.913rem;
  font-weight: 700;
  /* text-transform: uppercase; */
  /* position: absolute; */
  top: 0;
  right: 0;
  bottom: 0;
  transition: 300ms linear;
  transition-property: background, color;
  border: 0.063rem solid white;
  background: #4600ed;

  /* width: 9.375rem;
  min-width: 0.625rem;
  height: 2.688rem;
  min-height: 0.625rem;
  margin: auto 0;
  border-radius: 1.3125rem;
  box-shadow: none;
  color: #ffffff;
  font-family: inherit;
  font-size: 0.813rem;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  transition: 300ms linear;
  transition-property: background, color;
  border: 0.063rem solid white;
  background: #4600ed; */
}

.button-primary-middle input[type="submit"]:hover {
  background: #311b92;
  color: white;
  cursor: pointer;
  outline: 0;
}

@media screen and (max-width: 767px) {
  .button-primary-middle input[type="submit"] {
    position: relative;
    width: 100%;
    border: none;
  }
}

/*

.btn,
input[type="submit"] {
  cursor: pointer;
  border-radius: 0px;
  text-decoration: none;
  padding: 12px 18px;
  font-size: 12px;
  line-height: 19px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  letter-spacing: 3px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-mid {
  border-radius: 0px;
  text-decoration: none;
  padding: 14px 21px;
  font-size: 13px;
  line-height: 25px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  letter-spacing: 3px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-big {
  border-radius: 0px;
  text-decoration: none;
  padding: 18px 24px;
  font-size: 14px;
  line-height: 40px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  letter-spacing: 3px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.btn:hover,
input[type="submit"]:hover {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.btn-white {
  border: solid 2px #fff;
  background: transparent;
  color: #fff !important;
}
.btn-white:hover {
  border: solid 2px #fff;
  background: #fff;
  color: #1f1f1f !important;
}

.btn-dark,
input[type="submit"] {
  border: solid 2px #1f1f1f;
  background: transparent;
  color: #1f1f1f;
}
.btn-dark:hover,
input[type="submit"]:hover,
.btn-dark.active {
  border: solid 2px #1f1f1f;
  background: #1f1f1f;
  color: #fff;
}

.btn-color {
  background: transparent;
}
.btn-color:hover {
  color: #fff;
}

*/
/******************************/

.width100per {
  width: 100%;
}
.cd_iphone_slider #slider {
  width: 100% !important;
  height: 100% !important;
  background: url("iphone-black-big.svg") center top no-repeat;
  padding-top: 70px;
  padding-bottom: 150px;
}
.cd_iphone_slider .cd_items {
  width: 320px !important;
  margin: auto;
}

@media (max-width: 400px) {
  .cd_iphone_slider #slider {
    width: auto !important;
    height: auto !important;
    background: url("iphone-black-mini.svg") center top no-repeat;
  }
  .cd_iphone_slider .cd_items {
    width: 220px !important;
  }
}

@media (min-width: 401px) {
  .cd_iphone_slider #slider {
    width: auto !important;
    height: auto !important;
    background: url("iphone-black.svg") center top no-repeat;
  }
  .cd_iphone_slider .cd_items {
    width: 250px !important;
  }
}

@media (min-width: 768px) {
  .cd_iphone_slider #slider {
    width: 100% !important;
    height: 100% !important;
    background: url("iphone-black-big.svg") center top no-repeat;
    padding-top: 70px;
    padding-bottom: 150px;
  }
  .cd_iphone_slider .cd_items {
    width: 320px !important;
    margin: auto;
  }
}

@media (min-width: 992px) {
  .cd_iphone_slider #slider {
    width: 100% !important;
    height: 100% !important;
    background: url("iphone-black-big.svg") center top no-repeat;
    padding-top: 70px;
    padding-bottom: 150px;
  }
  .cd_iphone_slider .cd_items {
    width: 320px !important;
    margin: auto;
  }
}

@media (min-width: 1200px) {
  .cd_iphone_slider #slider {
    width: 100% !important;
    height: 100% !important;
    background: url("iphone-black-big.svg") center top no-repeat;
    padding-top: 70px;
    padding-bottom: 150px;
  }
  .cd_iphone_slider .cd_items {
    width: 320px !important;
    margin: auto;
  }
}

section {
  /*  padding: 8rem 0;*/
  /* padding: 3rem 0;*/
  padding-top: 0px;

  padding-bottom: 2rem;
}

.background-header {
  /* padding-top: 10rem;
  padding-bottom: calc(10rem - 56px);
  background-image: url(./assests/white_background.svg);*/
  /* background-color: white;*/
  background-position: center center;
  background-size: cover;
}

@media (min-width: 992px) {
  .background-header {
    height: 100vh;
    min-height: 650px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 991px) {
  .background-header {
    padding-bottom: 0;
  }
}
.footer_for_terms_privacy {
  border-top: 0.1px solid #424242;
}

.site-footer {
  /* padding: 170px 0 100px 0;*/
  padding: 85px 0 50px 0;

  display: block;
  width: 100%;
  clear: both;
  text-align: center;
  background: #311b92;
  color: white;
}

.site-footer .site-logo {
  width: 100%;
  clear: both;
  height: auto;
  display: block;
  margin: 1.875rem 0;
}

.site-footer .site-logo a {
  display: inline-block;
}

.site-social-icons {
  width: 100%;
  clear: both;
  padding: 25px 0 0 0;
}

.site-social-icons a {
  position: relative;
  text-align: center;
  margin: 0.3rem;
  transition: 300ms linear;
  transition-property: background, color, border-color;
}

.site-social-icons a:hover {
  color: white;
  transition: all ease 0.5s;
}

.site-social-icons i.fa,
.site-social-icons span {
  color: white;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 3rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.site-copyright {
  color: #708198;
  font-family: inherit;
  font-size: 13px;
  line-height: 1.5;
  width: 100%;
  clear: both;
  margin-top: 1.25rem;
}

@media screen and (max-width: 991px) {
  .site-footer {
    background: #311b92;
    padding: 60px 0;
    margin-top: 0;
  }
}

.buttondownload {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 25px 100px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 50px;
}

.site-subscribe {
  display: block;
  width: 100%;
  clear: both;
  color: white;
  position: relative;
  margin: 0;
}

.site-subscribe .main-heading-text {
  margin-top: -30px;
}

@media screen and (max-width: 1199px) {
  .site-subscribe {
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .site-subscribe {
    padding-bottom: 0.625rem;
  }
}

.site-subscribe .site-box {
  line-height: 0;
  padding: 0 2.1875rem;
  position: relative;
  top: 30px;
}

@media screen and (max-width: 1199px) {
  .site-subscribe .site-box {
    top: 0;
    max-width: 37.5rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2.5rem;
    padding-bottom: 1.25rem;
  }
}

@media screen and (max-width: 1199px) {
  .site-subscribe p {
    display: block;
    margin-bottom: 1.25rem;
  }
}

.site-subscribe label {
  width: 100%;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}

.site-subscribe input[type="email"],
.site-subscribe input[type="text"] {
  width: 100%;
  height: 2.688rem;
  line-height: 2.688rem;
  border: 0.063rem solid white;
  border-radius: 1.3125rem;
  background: #f2f4f5;
  display: block;
  margin: 0;
  font-weight: 400;
  color: #708198;
  padding: 0 11.25rem 0 1.25rem;
  border: #e9eaf4 solid 1px;
}

@media screen and (max-width: 767px) {
  .site-subscribe input[type="email"],
  .site-subscribe input[type="text"] {
    padding-right: 1.25rem;
  }
}

.site-subscribe input[type="submit"] {
  width: 9.375rem;
  min-width: 0.625rem;
  height: 2.688rem;
  min-height: 0.625rem;
  margin: auto 0;
  border-radius: 1.3125rem;
  box-shadow: none;
  color: #ffffff;
  font-family: inherit;
  font-size: 0.813rem;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  transition: 300ms linear;
  transition-property: background, color;
  border: 0.063rem solid white;
  background: #4600ed;
  /* 
    line-height: 2.688rem;

 */
}

.site-subscribe input[type="submit"]:hover {
  background: #311b92;
  color: white;
  cursor: pointer;
  outline: 0;
}

@media screen and (max-width: 767px) {
  .site-subscribe input[type="submit"] {
    position: relative;
    width: 100%;
    border: none;
  }
}

.site-subscribe .site-form {
  position: relative;
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
  clear: both;
}

@media screen and (max-width: 767px) {
  .site-subscribe .site-form {
    max-width: 25rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.site-subscribe .no-space {
  padding-left: 0;
  padding-right: 0;
}

/*********************************/

.carousel-indicators {
  bottom: -100px;
}
.carousel-indicators li {
  visibility: hidden;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner > .item {
  position: relative;
  display: none;
  -webkit-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}

.carousel-inner > .item > a > img,
.carousel-inner > .item > img {
  line-height: 1;
}

@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    -o-transition: -o-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }
  .carousel-inner > .item.active.right,
  .carousel-inner > .item.next {
    left: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  .carousel-inner > .item.active.left,
  .carousel-inner > .item.prev {
    left: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  .carousel-inner > .item.active,
  .carousel-inner > .item.next.left,
  .carousel-inner > .item.prev.right {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}

.carousel-inner > .active {
  left: 0;
}

.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}

.carousel-inner > .next {
  left: 100%;
}

.carousel-inner > .prev {
  left: -100%;
}

.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}

.carousel-inner > .active.left {
  left: -100%;
}

.carousel-inner > .active.right {
  left: 100%;
}

.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0);
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.carousel-control.left {
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5) 0,
    rgba(0, 0, 0, 0.0001) 100%
  );
  background-image: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5) 0,
    rgba(0, 0, 0, 0.0001) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0.0001))
  );
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5) 0,
    rgba(0, 0, 0, 0.0001) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
  background-repeat: repeat-x;
}

.carousel-control.right {
  right: 0;
  left: auto;
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.0001) 0,
    rgba(0, 0, 0, 0.5) 100%
  );
  background-image: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0.0001) 0,
    rgba(0, 0, 0, 0.5) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.0001)),
    to(rgba(0, 0, 0, 0.5))
  );
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.0001) 0,
    rgba(0, 0, 0, 0.5) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
  background-repeat: repeat-x;
}

.carousel-control:focus,
.carousel-control:hover {
  color: #fff;
  text-decoration: none;
  filter: alpha(opacity=90);
  outline: 0;
  opacity: 0.9;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next,
.carousel-control .icon-prev {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .icon-prev {
  left: 50%;
  margin-left: -10px;
}

.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next {
  right: 50%;
  margin-right: -10px;
}

.carousel-control .icon-next,
.carousel-control .icon-prev {
  width: 20px;
  height: 20px;
  font-family: serif;
  line-height: 1;
}

.carousel-control .icon-prev:before {
  content: "\2039";
}

.carousel-control .icon-next:before {
  content: "\203a";
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;
}

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #0009;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  border-radius: 10px;
}

.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.carousel-caption .btn {
  text-shadow: none;
}

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next,
  .carousel-control .icon-prev {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px;
  }
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: -10px;
  }
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: -10px;
  }
  .carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px;
  }
  .carousel-indicators {
    bottom: 20px;
  }
}

.carousel-inner > .item > a > img,
.carousel-inner > .item > img,
.img-responsive,
.thumbnail a > img,
.thumbnail > img {
  display: block;
  max-width: 100%;
  height: auto;
}

/***********************/
.full-container-paragraph-styling {
  padding-right: 0px;
  padding-left: 0px;
}

@media (min-width: 576px) {
  .full-container-paragraph-styling {
    padding-right: 100px;
    padding-left: 100px;
  }
}

@media (min-width: 768px) {
  .full-container-paragraph-styling {
    padding-right: 50px;
    padding-left: 0px;
  }
}

@media (min-width: 992px) {
  .full-container-paragraph-styling {
    padding-right: 100px;
    padding-left: 0px;
  }
}

@media (min-width: 1200px) {
  .full-container-paragraph-styling {
    padding-right: 100px;
    padding-left: 0px;
  }
}

.full-container-paragraph-styling-left-side {
  padding-right: 25px;
  padding-left: 25px;
}

@media (min-width: 576px) {
  .full-container-paragraph-styling-left-side {
    padding-right: 100px;
    padding-left: 100px;
  }
}

@media (min-width: 768px) {
  .full-container-paragraph-styling-left-side {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (min-width: 992px) {
  .full-container-paragraph-styling-left-side {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (min-width: 1200px) {
  .full-container-paragraph-styling-left-side {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.ui-steps li:hover .circle-icon {
  background: #4701ee;
  box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.09);
  color: #fff;
}

.ui-steps li {
  padding: 15px 0;
}

.ui-steps li:not(:last-child) {
  border-bottom: 1px solid #e8e9e9;
}

.circle-icon {
  box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.07);
  padding: 10px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 1.5rem;
  background-color: #fff;
  color: #4701ee;
  font-size: 48px;
  text-align: center;
  line-height: 80px;
  font-weight: 300;
  transition: all 0.3s ease;
}

@media (max-width: 576px) {
  .circle-icon {
    width: 40px;
    height: 40px;
    font-size: 22px;
    line-height: 25px;
  }
}

@media (min-width: 577px) {
  .circle-icon {
    width: 70px;
    height: 70px;
    font-size: 28px;
    line-height: 50px;
  }
}

@media (min-width: 768px) {
  .circle-icon {
    width: 70px;
    height: 70px;
    font-size: 28px;
    line-height: 50px;
  }
}

@media (min-width: 992px) {
  .circle-icon {
    width: 70px;
    height: 70px;
    font-size: 28px;
    line-height: 50px;
  }
}

@media (min-width: 1200px) {
  .circle-icon {
    width: 70px;
    height: 70px;
    font-size: 28px;
    line-height: 50px;
  }
}

/*
@media (max-width: 389px) {
  .circle-icon {
    width: 40px;
    height: 40px;
    font-size: 22px;
    line-height: 25px;
  }
}

@media (max-width: 880px) {
  .circle-icon {
    width: 70px;
    height: 70px;
    font-size: 28px;
    line-height: 50px;
  }
}

@media (min-width: 992px) {
  .circle-icon {
    width: 70px;
    height: 70px;
    font-size: 28px;
    line-height: 50px;
  }
}*/

@media (min-width: 1100px) {
  .circle-icon {
    width: 70px;
    height: 70px;
    font-size: 28px;
    line-height: 50px;
  }
}

.ui-steps li:hover .circle-icon {
  background: #4701ee;
  box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.09);
  color: #fff;
}

.ui-steps li {
  padding: 15px 0;
}

.ui-steps li:not(:last-child) {
  border-bottom: 1px solid #e8e9e9;
}

.h-styling {
  color: #242544;
}

.fontBoldTextLeft {
  font-weight: bold;
  text-align: left;
}
.font20px_textLeft {
  /*font-size: 20px;*/
  text-align: left;
}

/************/

.section-header-size-color {
  font-size: 2em;
  color: #242544;
  font-weight: bold;
}

.section-header {
  /*font-size: 1.5em;*/
  font-size: 2em;
  text-align: center;
  color: #242544;
  font-weight: bold;
}

.h-styling {
  color: #242544;
}

.font_top_heading_1 {
  /*font-family: "Poppins", sans-serif;*/
  color: #242544;
  font-size: 2.3rem;
  line-height: 40px;
}
.download_button_size {
  height: 85%; /*70%;*/
  width: 85%; /*70%;*/
}
.padding_bottom_mobile {
  padding-bottom: 25px;
}

@media (max-width: 991px) {
  .text-center-mobile-size {
    text-align: center;
  }
}
.font_top_heading_2 {
  /* font-family: "Poppins", sans-serif;*/
  color: #4701ee;
  font-size: 2.3rem;
  /*line-height: 40px;*/
}
.top_p {
  /* font-size: 20px;*/
  color: #37474f;
}
/*  white-space: nowrap;
*/
.subscriberTopHeading {
  font-size: 25px;

  font-weight: 300;
  color: #242544;
}

@media (min-width: 450px) {
  .subscriberTopHeading {
    font-size: 35px;
  }
}

@media (min-width: 576px) {
  .download_button_size {
    height: 100%; /*70%;*/
    width: 100%; /*70%;*/
  }
  .section-header-size-color {
    font-size: 3.5em;
  }
  .top_p {
    font-size: 1.3em;
    line-height: 25px;
  }
  .section-header {
    /* font-size: 2.3em;*/
    font-size: 3.5em;
    text-align: center;
  }

  .font_top_heading_2 {
    font-size: 3.5rem;
  }

  .font_top_heading_1 {
    font-size: 3.5rem;
  }
}

@media (min-width: 768px) {
  .section-header {
    /*font-size: 2.5em;*/
    text-align: center;
  }
  .center_text {
    /*font-size: 2.5em;*/
    text-align: center;
  }

  .font_top_heading_2 {
    font-size: 3.2rem;
  }

  .font_top_heading_1 {
    font-size: 3.2rem;
  }
}

@media (min-width: 992px) {
  .center_text {
    text-align: left;
  }
  .padding_bottom_mobile {
    padding-bottom: 0px;
  }
  .section-header {
    /* font-size: 3.5em;*/
    text-align: left;
  }

  .font_top_heading_2 {
    font-size: 3.8rem;
    white-space: nowrap;
    line-height: 50px;
  }

  .font_top_heading_1 {
    font-size: 3.8rem;
    white-space: nowrap;
    line-height: 50px;
  }
}

@media (max-width: 767px) {
  .center_text {
    /*font-size: 2.5em;*/
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .section-header {
    /* font-size: 3.5em;*/
    text-align: left;
  }

  /*.font_top_heading_2 {
    font-size: 4.5rem;
    white-space: nowrap;
    line-height: 60px;
  }

  .font_top_heading_1 {
    font-size: 4.5rem;
    white-space: nowrap;
    line-height: 60px;
  }*/
}

.full-container-paragraph-styling {
  padding-right: 0px;
  padding-left: 0px;
}

@media (min-width: 576px) {
  .full-container-paragraph-styling {
    padding-right: 100px;
    padding-left: 100px;
  }
}

@media (min-width: 768px) {
  .full-container-paragraph-styling {
    padding-right: 50px;
    padding-left: 0px;
  }
}

@media (min-width: 992px) {
  .full-container-paragraph-styling {
    padding-right: 100px;
    padding-left: 0px;
  }
}

@media (min-width: 1200px) {
  .full-container-paragraph-styling {
    padding-right: 100px;
    padding-left: 0px;
  }
}
.underline {
  max-width: 50px;
  border-width: 3px;
  border-color: #4701ee;
}
@media (min-width: 992px) {
  .underline {
    margin: 0px;
  }
}

@media (min-width: 1200px) {
  .underline {
    margin: 0px;
  }
}

.color_hr {
  border-color: #4701ee;
  max-width: 50px;
  border-width: 3px;
}
.top_p {
  /* font-size: 20px;*/
  color: #37474f;
}
